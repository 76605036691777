<template>
  <div>
    <van-nav-bar
      title="情景训练场"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="trainingground">
      <div class="title">
        <div class="ctx-title">
          <div class="title-ctx">问题</div>
          <div class="title-fk"></div>
        </div>
        <img
          class=""
          src="https://lesson.iapeap.com/images/EmotionalValue/sk.png"
          alt=""
        />
      </div>
      <div class="task_info" v-html="formattedText"></div>
      <div v-for="(item, index) in SceneList" :key="index">
        <div class="daan" @click="toitem(item)" v-if="item.is_check == 'false'">
          {{ item.option }}
        </div>
        <div
          class="daan-xz"
          @click="toitem2(item)"
          v-if="item.is_check == 'true'"
        >
          {{ item.option }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getScene, setComplated, saveScene,memberLog } from "../../api/login";

export default {
  data() {
    return {
      id: "",
      task_info: "",
      SceneList: [],
      starttime: "", //  进入页面时间
      endtime: "", //  退出页面时间
      time: "", //   页面累积时间
    };
  },
  created() {},
  computed: {
    formattedText() {
      return this.task_info.replace(/\n/g, "<br>");
    },
  },
  mounted() {
    this.id = window.localStorage.getItem("listid");
    this.getgetScene();

    this.timer = setInterval(() => {
      this.time++;
      // console.log("页面累积浏览时间是：",this.time,"秒")
    }, 1000); // 每秒触发一次
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    this.starttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("进入页面的系统时间是：", this.starttime);
  },
  methods: {
    //点击完成
    onsetComplated() {
      let data = { id: this.id, userId: window.localStorage.getItem("userId") };
      setComplated(data).then((res) => {});
    },
    getgetScene() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: "正在加载...",
        forbidClick: true,
      });
      let data = {
        id: this.id,
        userId: window.localStorage.getItem("userId"),
      };
      getScene(data).then((res) => {
        this.task_info = res.taskInfo.task_info;

        this.SceneList = res.data;
        Toast.clear();
      });
    },
    toitem(item) {
      console.log(item);
      this.onsetComplated();
      let data = {
        userId: window.localStorage.getItem("userId"),
        id: item.id,
        emtId: this.id,
      };
      saveScene(data).then((res) => {});

      console.log(item);
      this.$router.push({
        path: "/setout/traininggroundItem",
        query: { item: item },
      });
    },

    toitem2(item) {
      console.log(item);
      this.$router.push({
        path: "/setout/traininggroundItem",
        query: { item: item },
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },





  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("离开页面的系统时间是", this.endttime);

    //把参数传给后端接口，starttime、endttime、time
    let data = {
      startTime: this.starttime,
      endTime: this.endttime,
      userId: window.localStorage.getItem("userId"),
      pageName: "情景训练场",
      timeStamp: this.time,
    };
    memberLog(data).then((res) => {});
  },
};
</script>

<style lang="less">
.task_info {
  margin-top: 10px;
  text-indent: 20px;
  font-size: 13px;
  font-weight: 500;
  color: #000025;
  line-height: 20px;
  letter-spacing: 1px;
}
.navbar {
  background-color: #ededed;
}
/deep/ .van-nav-bar .van-icon {
  color: #464646;
}
.trainingground {
  background-color: #f4fafe;
  min-height: 100vh;
  padding: 20px 20px 40px 20px;
  box-sizing: border-box;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 54px;
      height: 46px;
    }
  }
  .daan {
    width: 268px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    margin: 0 auto;
    margin-top: 20px;
    line-height: 40px;
    padding: 0 20px;
    box-sizing: border-box;
    min-height: 40px;
    font-size: 12px;
    font-weight: 500;
    color: #000025;
    letter-spacing: 1px;
  }
  .daan-xz {
    width: 268px;
    background: #d3e3f7;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    margin: 0 auto;
    margin-top: 20px;
    line-height: 40px;
    padding: 0 20px;
    box-sizing: border-box;
    color: #629ee9;
    font-size: 12px;
    font-weight: 500;
    color: #000025;
    letter-spacing: 1px;
  }
}
.ctx-title {
  .title-ctx {
    z-index: 999;
    position: relative;
    font-size: 18px;
    font-weight: 500;
    color: #000025;
    line-height: 25px;
  }
  .title-fk {
    width: 35px;
    height: 5px;
    background: #629ee9;
    position: relative;
    bottom: 8px;
    z-index: 998;
    transform: skew(-25deg);
  }
}
</style>
